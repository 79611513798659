import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, useParams, useLocation, useHistory } from "react-router-dom";
import "./App.scss";

import Header from "./components/header/header";
import Menu from "./components/menu/menu";
import Popup from "./components/popup/popup";
import Notification from "./components/notification/notification";
import SelectionWindow from "./components/selectionWindow/selectionWindow";
import ChooseRole from "./pages/chooseRole/chooseRole";

import SignUp from "./pages/signUp/signUp";
import InvitationPage from "./pages/invitationPage/invitationPage";
import LogIn from "./pages/logIn/logIn";
import RecoveryPassword from "./pages/recoveryPassword/recoveryPassword";
import Privacy from "./pages/privacy/privacy";

import { getUser, getTags, getLanguages } from "./service/requests";

import ProtectedRoutes from "./ProtectedRoutes"; //Authenticated routes
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

//styles
import "./index.scss";

import { currentUser } from "./actions/currentUser";
import { closeSelectionWindow } from './actions/selectionWindow';
import { setTags } from "./actions/tags";
import { setLanguages } from "./actions/languages";

const mapStateToProps = (state) => ({
  ...state.currentUser,
  ...state.selectionWindow
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setTags: (payload) => {
    return dispatch(setTags(payload));
  },
  closeSelectionWindow: () => {
    return dispatch(closeSelectionWindow());
 },
 setLanguages: (payload) => {
  return dispatch(setLanguages(payload));
},
});

function App(props) {
  const { currentUser, user, setTags, setLanguages, showSelectionWindow, closeSelectionWindow } = props;

  const [authChecked, setAuthChecked] = React.useState(false);
  const history = useHistory();
  let isInvitationPage = history.location.pathname.includes("invite");
  let location = useLocation();

  const loadLanguages = async() => {
    try {
      const resLangs = await getLanguages();
      setLanguages({ languages: resLangs.data.langs });
    } catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    loadLanguages();

    (async function() {
      const loadData = async () => {
        try {
          const resTags = await getTags();
          setTags({ tags: resTags.data.tags });
        } catch (error) {
          console.log(error);
        }
      };
  
      if (token != null) {
        const resp = await getUser().catch(console.log);
  
        if (!resp) {
          localStorage.removeItem("accessToken");
          history.push("/login");
          return setAuthChecked(true);
        }
  
        if(resp?.data?.user) {
          currentUser({ user: resp.data.user });
          setAuthChecked(true);
          loadData();
        } else {
          localStorage.removeItem("accessToken");
          history.push("/login");
          return setAuthChecked(true);
        }
      } else {
        // history.push("/login");
        setAuthChecked(true);
      }
  
      // get correct theme after page reload on login 
      const theme = localStorage.getItem("theme");
      const root = window.document.documentElement;

      if(theme == 'undefined') {
          const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
          if (darkThemeMq.matches) {
            root.classList.add('dark');
          } else {
            root.classList.add('light');
          }
      } else {
          root.classList.add(theme);
      }
    })()

  }, []);


  let  { id }   = useParams();
  useEffect(() => {
    isInvitationPage = history.location.pathname.includes("invite");
    if(isInvitationPage) {
      let id = history.location.pathname.slice(8);
      console.log('id', id);
      localStorage.setItem("invitationCode", id);
    }
  }, [location]);

  useEffect(() => {
    if (showSelectionWindow) {
      closeSelectionWindow();
    }
  }, [location]);

  const isAuthenticated = user ? true : false;

  return (
    <>
      <Suspense fallback={<div className='animatedLoaderLogoWrapper'><img className='animatedLoaderLogo' src="/images/logo.svg" /></div> }>
        <div className="flex">
          {user && <Menu />}

          <div className="bg-bgLightMode dark:bg-bgDarkMode contentWrapper">
            {user && <Header />}

            <SelectionWindow />
            <Popup />
            <Notification />

            {authChecked && (
              <Switch>
                <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
                  <LogIn />
                </PublicRoute>
                <PublicRoute path="/signUp" isAuthenticated={isAuthenticated}>
                  <SignUp />
                </PublicRoute>
                <PublicRoute path="/chooseRole" isAuthenticated={isAuthenticated}>
                  <ChooseRole />
                </PublicRoute>
                <PublicRoute
                  path="/recoveryPassword"
                  isAuthenticated={isAuthenticated}
                >
                  <RecoveryPassword />
                </PublicRoute>

                <PublicRoute
                  path="/privacy"
                  // isAuthenticated={isAuthenticated}
                >
                  <Privacy />
                </PublicRoute>

                <PublicRoute
                  path="/chooseRole"
                  isAuthenticated={isAuthenticated}
                >
                  <ChooseRole />
                </PublicRoute>

                <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
                  <ProtectedRoutes user={user}/>
                </PrivateRoute>

              </Switch>
            )}
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

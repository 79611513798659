import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import TextField from "@material-ui/core/TextField";

const mapStateToProps = (state) => ({
  ...state.currentUser,
  ...state.languages
});

const LanguageSelector = (props) => {

  const { 
    user, 
    setLang, 
    lang,
    languages
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(lang 
    ?   languages.find((x) => x.code === lang) 
    :   ""
  );
  
  return (
    <>
      <Autocomplete
        options={languages}
        renderOption={(option) => {
          {
            return (
              <div className="text-dark dark:text-whitish">
                <ReactCountryFlag countryCode={option.flag} svg />
                <span className="ml-3">{option.name}</span>
              </div>
            );
          }
        }}
        getOptionLabel={(option) => option.name}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setLang(newValue.code);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="language"
        size="small"
        popupIcon={<ArrowDropDownRoundedIcon className="text-purple" />}
        disableClearable
        renderInput={(params) => {
          console.log("12", params);
          let flagCode;
          if (
            languages.find((x) => x.name === params.inputProps.value) &&
            languages.find((x) => x.name === params.inputProps.value)?.flag
          ) {
            flagCode = languages.find(
              (x) => x.name === params.inputProps.value
            ).flag;
          }

          return (
            <div style={{ position: "relative" }}>
              {flagCode && (
                <span
                  className="plainLangSelector__flagWrapper"
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "40px",
                    transform: "translateY(50%)",
                    marginLeft: "5px",
                    zIndex: 100,
                  }}
                >
                  <ReactCountryFlag countryCode={flagCode} svg />
                </span>
              )}
              <TextField
                {...params}
                className="plainLangSelector"
                label=""
                variant="outlined"
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default connect(mapStateToProps, null)(LanguageSelector);

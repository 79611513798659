import React, { useEffect, useState } from "react";
import "./languageSelector.scss";
import { connect } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const mapStateToProps = (state) => ({
  ...state.currentUser,
  ...state.languages
});

const LanguagesSelectorFromTo = (props) => {
  const {
    user,
    setLang,
    onInputChange,
    classLang,
    classLearnLang,
    languages
  } = props;

  const [valueFrom, setValueFrom] = useState({ code: "auto" });
  const [inputValueFrom, setInputValueFrom] = useState("");
  const [valueTo, setValueTo] = useState(
    languages.find((x) => x.code === user.nativeLang)
  );
  const [inputValueTo, setInputValueTo] = useState("");
  const [isLangSelectorUsed, setIsLangSelectorUsed] = useState(false);

  useEffect(() => {
    if(classLang) {
      setValueTo(languages.find((x) => x.code === classLang))
    }

    if(classLearnLang) {
      setValueFrom(languages.find((x) => x.code === classLearnLang))
    }
  }, [])

  useEffect(() => {
    if (isLangSelectorUsed) {
      setLang("from", valueFrom.code);
      setLang("to", valueTo.code);
    }
  }, [valueFrom, valueTo]);

  const checkFieldFrom = () => {
    const foundLanguage = languages.find(
      (x) => x.code === inputValueFrom.toLowerCase()
    );
    if (foundLanguage) {
      console.log("language found");
      setValueFrom(foundLanguage);
    } else {
      setInputValueFrom(valueFrom.code);
      console.log("language NOT found");
    }
    setIsLangSelectorUsed(true);
  };

  const checkFieldTo = (e) => {
    const foundLanguage = languages.find(
      (x) => x.code === inputValueTo.toLowerCase()
    );
    if (foundLanguage) {
      console.log("language found");
      setValueTo(foundLanguage);
    } else {
      setInputValueTo(valueTo.code);
      console.log("language NOT found");
    }
    setIsLangSelectorUsed(true);
  };

  return (
    <div className="flex items-center">
      <div className="langSelectorWrapper">
        <Autocomplete
          freeSolo
          defaultValue={languages[0].code}
          value={valueFrom}
          options={languages}
          renderOption={(option) => {
            {
              return (
                <div className="text-dark dark:text-whitish">
                  <ReactCountryFlag countryCode={option.flag} svg />
                  <span className="ml-3">{option.name}</span>
                </div>
              );
            }
          }}
          getOptionLabel={(option) => option.code}
          onChange={(event, newValue) => {
            setValueFrom(newValue);
            setIsLangSelectorUsed(true);
          }}
          inputValue={inputValueFrom}
          onInputChange={(event, newInputValue) => {
            setInputValueFrom(newInputValue);
          }}
          id="language"
          size="small"
          popupIcon={<ArrowDropDownRoundedIcon className="text-purple" />}
          disableClearable
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className="laguageSelectorMult__input"
                variant="outlined"
                onBlur={() => checkFieldFrom()}
              />
            );
          }}
        />
      </div>

      <ArrowRightAltIcon fontSize="small" className="text-lightPurple" />

      <div className="langSelectorWrapper">
        <Autocomplete
          freeSolo
          options={languages}
          renderOption={(option) => {
            {
              return (
                <div className="text-dark dark:text-whitish">
                  <ReactCountryFlag countryCode={option.flag} svg />
                  <span className="ml-3">{option.name}</span>
                </div>
              );
            }
          }}
          getOptionLabel={(option) => option.code}
          value={valueTo}
          onChange={(event, newValue) => {
            setValueTo(newValue);
            setIsLangSelectorUsed(true);
          }}
          inputValue={inputValueTo}
          onInputChange={(event, newInputValue) => {
            setInputValueTo(newInputValue);
            onInputChange();
          }}
          id="language"
          size="small"
          popupIcon={<ArrowDropDownRoundedIcon className="text-purple" />}
          disableClearable
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className="laguageSelectorMult__input"
                variant="outlined"
                onBlur={() => checkFieldTo()}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(LanguagesSelectorFromTo);

import React from 'react';
import { connect } from 'react-redux';
import './selectionWindow.scss';

import AddRoundedIcon from '@material-ui/icons/AddRounded';

// icons 
import deleteIcon from '../../img/ico/deleteIconPopup.svg';
import closeIcon from '../../img/ico/close.svg';

import { deleteWordsFromCollection } from '../../service/requests';

import { closeSelectionWindow } from '../../actions/selectionWindow';
import { openPopup } from '../../actions/popup';
import { openNotification } from '../../actions/notification';


const mapStateToProps = state => ({
   ...state.selectionWindow
});

const mapDispatchToProps = dispatch => ({
   closeSelectionWindow: () => {
      return dispatch(closeSelectionWindow());
   },
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   }
})

const SelectionWindow = (props) => {

   const {
      showSelectionWindow,
      closeSelectionWindow,
      openPopup,
      info,
      openNotification,
   } = props;

   const words = info.words;

   const onDeleteWords = async () => {
      const wordsId = words.map(word => word._id);
      try {
         await deleteWordsFromCollection(info.collectionId, wordsId );
         info.onDeleteWordsEvent(words);
         closeSelectionWindow()
      }
      catch (error) {
         console.log(error);
         openNotification({text: 'Something went wrong, try again', notificationType: 'error' });
      }

   }

   return (
      <>
         {showSelectionWindow &&
            <div className="selectedPopup">
               <div className="flex">
                  <div className="cursor-pointer p-1 flex items-center" onClick={() => closeSelectionWindow()}><img src={closeIcon} className="selectedPopup__closeBtn" /></div>
                  <div className="selectedPopup__numberWords">{words.length}</div>
                  <div className="text-xs text-white mr-5 ml-2">{words.length === 1 ? "item" : "items"} selected</div>
               </div>
               <div className="flex">
                  <div className="selectedPopup__btn" onClick={() => openPopup({ currentPopup: "AddToCollection", info: {words: words, collectionId : info.collectionId}})} ><AddRoundedIcon fontSize="small" className="mr-2"/>Add To Collection</div>
                  {(!info.classId || info.userRole === 'teacher') &&
                     <div className="py-2 px-4 bg-deleteIcon rounded-md hover:bg-darkRed cursor-pointer transition" onClick={onDeleteWords}><img src={deleteIcon} /></div>
                  }
               </div>
            </div>
         }
      </>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SelectionWindow);
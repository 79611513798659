import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

import './loadCollection.scss'
import { PurpleButtonSmall } from '../../buttons/buttons';
import ButtonBase from '@material-ui/core/ButtonBase';
import { loadCollection } from '../../../service/requests';
import { closePopup } from '../../../actions/popup';
import { openNotification } from '../../../actions/notification';

// images 
import detailsTemplate from '../../../img/detailsTemplate.svg';
import detailsOriginal from '../../../img/detailsOriginal.svg';
import detailsTranscription from '../../../img/detailsTranscription.svg';
import detailsTranslated from '../../../img/detailsTranslated.svg';

// icons
import prevStep from '../../../img/ico/prevStepIcon.svg';

const mapStateToProps = state => ({
   ...state.collections,
   ...state.themeMode
});

const mapDispatchToProps = dispatch => ({
   closePopup: (payload) => {
      return dispatch(closePopup(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
})


const LoadCollection = (props) => {

   const {
      collections,
      info,
      closePopup,
      openNotification,
      themeMode
   } = props;


   const [selectedCollection, setSelectedCollection] = useState(info.collection);
   const [currentStep, setCurrentStep] = useState(1);
   const [allCollections, setAllCollections] = useState(collections);
   const [isLoading, setIsLoading] = useState(false);

   const [format, setFormat] = useState("pdf");

   const [details, setDetails] = useState([
      { name: "original", checked: true, image: detailsOriginal, style: "absolute top-9 left-3" },
      { name: "transcription", checked: false, image: detailsTranscription, style: "absolute top-9 left-20" },
      { name: "translate", checked: true, image: detailsTranslated, style: "absolute top-9 right-3" },
   ]);
   const [error, setError] = useState();


   useEffect(() => {
      if(!collections.length) return
      const updatedCollections = collections.map(x => {
         x.checked = false;
         return x;
      })

      updatedCollections.find(x => x._id === selectedCollection._id).checked = true;
      setAllCollections(updatedCollections);
   }, [collections]);


   const onCollectionSelect = (collection) => {
      const updatedCollections = allCollections.map(x => {
         x.checked = false;
         return x;
      })

      updatedCollections.find(x => x._id === collection._id).checked = true;
      setAllCollections(updatedCollections);

      const selectedElem = updatedCollections.find(x => x.checked === true);
      setSelectedCollection(selectedElem);
   }

   const onDetailSelect = (detail) => {
      const updatedDetails = details.map(x => {
         if (x.name === detail.name) {
            x.checked = !x.checked
         }
         return x;
      });
      setDetails(updatedDetails);
   }


   const showExample = () => {
      return details.map(detail => {
         if (detail.checked && detail.name != 'transcription') {
            return (
               <img src={detail.image} className={detail.style} />
            )
         }
      })
   }

   const showStepContent = () => {
      // if (currentStep === 1) {
      //    return (
      //       <div>
      //          <div className="text-dark text-base font-medium text-center">Collection selection</div>

      //          <div className="flex flex-wrap my-4">

      //             {collections.map(collection => {
      //                return (
      //                   <div className={collection.checked ? "load__selectCollection--active" : "load__selectCollection"} onClick={() => onCollectionSelect(collection)}>{collection.name}</div>
      //                )
      //             })}

      //          </div>

      //          <div className="mx-auto w-min rounded-lg overflow-hidden">
      //             <ButtonBase><div className="mx-auto bg-darkPurple text-sm text-white py-2 px-10 w-max rounded-md cursor-pointer hover:bg-hoverPurple transition mt-3" onClick={() => setCurrentStep(currentStep + 1)} >Next</div></ButtonBase>
      //          </div>
      //       </div>
      //    )
      // }
      if (currentStep === 1) {
         return (
            <div>
               <div className="text-dark dark:text-whitish font-medium text-center text-base">Format selection</div>
               <div className="flex flex-wrap justify-center my-5 load__formatSelection">
                  <div className={format === "pdf" ? "load__item--active" : "load__item"} onClick={() => setFormat("pdf")} >PDF</div>
                  <div className="load__item--disabled">JPEG</div>
                  <div className="load__item--disabled">EXCEL</div>
               </div>

               <div className="mx-auto w-min rounded-lg overflow-hidden">
                  <ButtonBase><div className="mx-auto bg-darkPurple text-sm text-white py-2 px-10 w-max rounded-md cursor-pointer hover:bg-hoverPurple transition mt-3" onClick={() => setCurrentStep(currentStep + 1)} >Next</div></ButtonBase>
               </div>
            </div>
         )
      }

      else if (currentStep === 2) {
         return (
            <div>
               <img src={prevStep} className={themeMode === 'dark' ? "filter invert absolute top-6 left-6 cursor-pointer" : "absolute top-6 left-6 cursor-pointer"} onClick={() => setCurrentStep(currentStep - 1)} />
               <div className="text-dark dark:text-whitish font-medium text-center text-base">Details</div>

               <div className="flex flex-wrap justify-between my-5">
                  {details.map(detail => {
                     return (
                        <div className={detail.name === "transcription" ? "load__item--disabled" :  detail.checked ? "load__item--active" : "load__item"} onClick={detail.name === "Hide transcription" ? undefined : () => onDetailSelect(detail)}>{detail.name.charAt(0).toUpperCase() + detail.name.slice(1)}</div>
                     )
                  })}
               </div>

               <div className="relative w-max mx-auto my-5">
                  <div className={themeMode === 'dark' ? "filter invert" : "" }>
                     <img src={detailsTemplate} />
                     {showExample()}
                  </div>
               </div>

               <div className="mx-auto w-min rounded-lg">
                  <PurpleButtonSmall isLoading={isLoading} text="Download" onClick={onDownloadCollection} /> 
               </div>

               {error && <div className="absolute bottom-7 left-5 text-xs w-32 text-redError">{error}</div>}
            </div>
         )
      }
   }

   const onDownloadCollection = async () => {
      let isDetailsError = details.every((x) => !x.checked);
      if (isDetailsError) {
         setError("Please set at least one visible column");
         return;
      }
      
      setIsLoading(true);
      setError(false);

      const hiddenColumns = details.filter((x) => !x.checked && x.name != "transcription");
      const showedColumn = () => {
        if (hiddenColumns.length < 1) {
          return "all";
        }
        return hiddenColumns.some((x) => x.name == "original") ? "translate" : "original";
      };
      
      try {
         const res = await loadCollection(selectedCollection._id, { show: showedColumn() });
         window.open(`${res.data.path}`, '_blank');
         closePopup();
      } catch(e) {
         openNotification({text: 'Something went wrong', notificationType: 'error' });
      }
      setIsLoading(false)
   }


   return (
      <section className="my-6 mx-16">
         <div className="uppercase text-gray dark:text-whitish text-xs mb-3 font-light text-center">download</div>

         {showStepContent()}

         <div className="text-gray font-light absolute bottom-5 right-6 text-xs">step {currentStep} of 2</div>
      </section>
   )
}


export default connect(
   mapStateToProps,
   mapDispatchToProps
)(LoadCollection);
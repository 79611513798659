import React from 'react'
import { connect } from 'react-redux';
import { PurpleButtonSmall, EmptyButtonSmall, PurpleButton, EmptyButton } from '../../../components/buttons/buttons';
import {PopupTitle} from '../../title/title';
import { deleteWordFromCollection } from '../../../service/requests';

import { closePopup } from '../../../actions/popup';
import { openNotification } from '../../../actions/notification';
import trashIcon from '../../../img/ico/trashIcon.svg'


const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   }
})

const DeleteWord = (props) => {

   const {
      closePopup,
      info,
      openNotification
   } = props;


   info.setMoreAction();

   const onWordDelete = async () => {
      try {
         closePopup()
         await deleteWordFromCollection(info.collectionId, info.wordId);
         info.onConfirmEvent();
         // openNotification({text: 'Something went wrong, try again', notificationType: 'error' });
      }
      catch (error) {
         console.log(error);
         openNotification({text: 'Something went wrong, try again', notificationType: 'error' });
      }

   }

   return (
      <section className="my-10 mx-20">
         <img src={trashIcon} className="m-auto"/>
         <PopupTitle title="Delete word?"/>

         <div className="mt-3 text-center text-dark dark:text-white font-light">
            Are you sure you want to remove a word from the collection?
         </div>

         <div className="mx-auto w-max mt-10 flex gap-5">
            <EmptyButtonSmall text="Cancel" onClick={() => closePopup()} />
            <PurpleButtonSmall text="Yes, delete" onClick={() => onWordDelete()} />
         </div>

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(DeleteWord);

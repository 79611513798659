import React, { useState } from "react";
import { connect } from "react-redux";
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import {
  PurpleButtonSmall,
  NextStepButton,
} from "../../../components/buttons/buttons";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import lockCloseIcon from "../../../img/ico/lockCloseIcon.svg";
import lockOpenIcon from "../../../img/ico/lockOpenIcon.svg";
import { changePassword } from "./../../../service/requests";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const ChangePassword = (props) => {
  const {
    closePopup,
    openNotification
  } = props;

  const [isNewPassShow, setIsNewPassShow] = useState(false);


  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  const [passwordError, setPasswordError]= useState({isError: false, errorText: ''});


  const [showPassword, setShowPassword] = useState(false);

  // const [isOldPassConfirmed, setIsOldPassEConfirmed] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  
  const validate = () => {
    if(newPassword === newPasswordRepeat){
      return true;
    } else {
      return false;
    }
  }


  const onPasswordChange = async () => {
    console.log("onPasswordChange");

    const isValid = validate();

    if(isValid) {
      try {

        const res = await changePassword({
          newPassword: newPassword,
          oldPassword: password
        });


        if(res.response && !res.response.data.ok){
          openNotification({text: 'Something went wrong', notificationType: 'error' });
          setIsNewPassShow(false);
          setPasswordError({isError: true, errorText: 'Password invalid' });
        }

        if(res.data.ok) {
          openNotification({text: 'Password was changed successfully', notificationType: 'success' });
          closePopup();
        } 

      } catch(e) {
        console.log('e', e)
      }
      
    } else {
      setPasswordError({isError: true, errorText: 'Passwords do not match' });
    }


  };


  const onNextStep =() => {
        if(password.length > 0){
          setIsNewPassShow(true)
        } else {
          setPasswordError({isError: true, errorText: 'Please ented your old password'})
        }
  }

  return (
    <section className="my-8 mx-20">
      <img
        src={isNewPassShow ? lockOpenIcon : lockCloseIcon}
        className="m-auto"
      />
      <div className="mt-5 text-center text-dark dark:text-white font-light text-sm">
        Change Password
      </div>

      <div className="mt-3 text-dark dark:text-white text-xl font-medium text-center">
        Enter your {isNewPassShow && "new"} password
      </div>


      {passwordError.isError &&  <div className="w-9/12 mx-auto absolute bottom-20"><div className="text--error w-9/12 mx-auto">{passwordError.errorText}</div></div>}


      {isNewPassShow ? (
        <>
          <div className="w-9/12 mx-auto mt-8">
            <Input
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={newPassword}
              fullWidth
              autoFocus={true}
              error={passwordError.isError}
              placeholder="Enter new password"
              className="text-dark dark:text-white"
              onChange={(e) => {setNewPassword(e.target.value); setPasswordError({isError: false, errorText: ""})}}

              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon className="text-purple dark:text-white" /> : <VisibilityIcon className="text-purple dark:text-white" />}
                </IconButton>
              }
              label="Password"
            />
          </div>
          <div className="w-9/12 mx-auto mt-8">
            <Input
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={newPasswordRepeat}
              fullWidth
              className="text-dark dark:text-white"
              error={passwordError.isError}
              placeholder="Repeat new password"
              onChange={(e) => {setNewPasswordRepeat(e.target.value); setPasswordError({isError: false, errorText: ""})}}

              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon className="text-purple dark:text-white" /> : <VisibilityIcon className="text-purple dark:text-white" />}
                </IconButton>
              }
              label="Password"
            />
          </div>
        </>
      ) : (
        <div className="w-9/12 mx-auto mt-8 text-dark dark:text-white">
          <Input
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            error={passwordError.isError}
            fullWidth
            autoFocus={true}
            className="text-dark dark:text-white"
            placeholder="Enter your password"
            onKeyDown={(e) => e.keyCode == 13 && onNextStep() }
            onChange={(e) => {setPassword(e.target.value); setPasswordError({isError: false, errorText: ""})}}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => handleMouseDownPassword(e)}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon className="text-purple dark:text-white" /> : <VisibilityIcon className="text-purple dark:text-white" />}
              </IconButton>
            }
            label="Password"
          />
        </div>
      )}

      <div className="mx-auto w-max mt-10 flex gap-5">
        {isNewPassShow ? (
          <PurpleButtonSmall
            text="Confirm"
            onClick={() => onPasswordChange()}
          />
        ) : (
          <NextStepButton text="Next" onClick={onNextStep} />
        )}
      </div>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(ChangePassword);
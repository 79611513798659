import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import "./chooseRole.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

import {PurpleButtonWithLoading} from "../../components/buttons/buttons";

import ReactCountryFlag from "react-country-flag";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useHistory, useLocation} from "react-router-dom";

// api
import {getUser, getCollections, createUser, googleAuthContinue, getLocation} from "../../service/requests";

// img
import logo from "../../img/logorwAnimated.svg";

// ico
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

// actions
import {currentUser} from "../../actions/currentUser";
import {setCollections} from "../../actions/collections";


const mapStateToProps = (state) => ({
  ...state.languages
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setCollections: (payload) => {
    return dispatch(setCollections(payload));
  },
});

const ChooseRole = (props) => {
  const {currentUser, setCollections, languages} = props;
  const userLang = navigator.language || navigator.userLanguage; 

  const history = useHistory();
  const location = useLocation();

  const [role, setRole] = useState("student");
  const [value, setValue] = React.useState(languages.find(x => x.code === userLang));
  const [inputValue, setInputValue] = React.useState("");

  const [user, setUser] = useState();
  const [isGoogleAuth, setIsGoogleAuth] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state) {
      setUser(location.state.user);
      setIsGoogleAuth(location.state.isGoogleAuth);
    }
  }, []);

  const onNext = async () => {
    setIsLoading(true);
    if (isGoogleAuth) {
      let userInfo = {role: role, nativeLang: value.code};
      try {
        const res = await googleAuthContinue(userInfo);
        console.log("res", res);

        if (res.data.ok) {
          localStorage.setItem("accessToken", res.data.token);
          currentUser({user: res.data.user});
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      let updatedUser = {...user, ...{role: role, nativeLang: value.code}};

      try {
        const resCreatedUser = await createUser(updatedUser);
        localStorage.setItem("accessToken", resCreatedUser.data.token);

        const resUser = await getUser();
        currentUser({user: resUser.data.user});

        let invitationCode = localStorage.getItem("invitationCode");
        if (invitationCode) {
          history.push(`/invite/${invitationCode}`);
        } else {
          history.push("/");
        }

        const resCollections = await getCollections();
        setCollections({collections: resCollections.data.collections});
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="authWrapper">
      <object type="image/svg+xml" className="w-32 mx-auto" data={logo}></object>
      <div className="chooseRole relative">
        {isLoading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress size={100} />
          </div>
        )}

        <div className={isLoading ? "opacity-10 pointer-events-none" : ""}>
          <div className="text-dark dark:text-whitish text-center text-lg mt-14 font-light">Choose your role</div>
          <div className="chooseRole__container">
            <div
              className={role === "student" ? "chooseRole__role--active" : "chooseRole__role"}
              onClick={() => setRole("student")}
            >
              <img src="/images/ico/check.svg" alt="checkIcon" className="chooseRole__checkRoleIcon" />
              <img src="/images/ico/studentRole.svg" alt="Student icon" />
              <div className="chooseRole__roleName">Student</div>
            </div>

            <div
              className={role === "teacher" ? "chooseRole__role--active" : "chooseRole__role"}
              onClick={() => setRole("teacher")}
            >
              <img src="/images/ico/check.svg" alt="checkIcon" className="chooseRole__checkRoleIcon" />
              <img src="/images/ico/teacherRole.svg" alt="Student icon" />
              <div className="chooseRole__roleName">Teacher</div>
            </div>

            <div className="chooseRole__disabled" onClick={() => setRole("organization")}>
              {/* <div  className={role === 'organization' ? "chooseRole__role--active" : "chooseRole__role" }  onClick={() => setRole("organization")}> */}
              <img src="/images/ico/check.svg" alt="checkIcon" className="chooseRole__checkRoleIcon" />
              <img src="/images/ico/organizationRole.svg" alt="OrganizationRole icon" />
              <div className="chooseRole__roleName">Organization</div>
            </div>
          </div>

          <div className="text-dark dark:text-whitish text-center text-lg mt-14 font-light">
            Select your native language
          </div>

          <Autocomplete
            options={languages}
            renderOption={(option) => {
              {
                return (
                  <div>
                    <ReactCountryFlag countryCode={option.flag} svg />
                    <span className="ml-3 text-dark dark:text-white">{option.name}</span>
                  </div>
                );
              }
            }}
            getOptionLabel={(option) => option.name}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            className="chooseRole__inputWrapper"
            popupIcon={<ArrowDropDownRoundedIcon className="text-purple" />}
            disableClearable
            renderInput={(params) => {
              console.log("12", params);
              let flagCode;
              if (
                languages.find((x) => x.name === params.inputProps.value) &&
                languages.find((x) => x.name === params.inputProps.value).flag
              ) {
                flagCode = languages.find((x) => x.name === params.inputProps.value).flag;
              }

              return (
                <div style={{position: "relative"}}>
                  {flagCode ? (
                    <span
                      style={{
                        position: "absolute",
                        top: "-4px",
                        left: "10px",
                        transform: "translateY(50%)",
                        marginLeft: "5px",
                        zIndex: 100,
                      }}
                    >
                      <ReactCountryFlag countryCode={flagCode} svg />
                    </span>
                  ) : (
                    <span
                      style={{
                        position: "absolute",
                        top: "15px",
                        left: 0,
                        marginLeft: "15px",
                        zIndex: 100,
                        backgroundColor: "#6a75ca1b",
                        width: "17px",
                        height: "13px",
                      }}
                    ></span>
                  )}
                  <TextField {...params} className="chooseRole__input" label="" variant="outlined" />
                </div>
              );
            }}
          />

          <div className="mx-auto w-max mt-14">
            <PurpleButtonWithLoading text="Next" onClick={onNext} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseRole);

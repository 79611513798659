import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import LanguageSelector from '../../languageSelector/languageSelector';
import {PopupTitle} from '../../title/title';

// icons 
import deleteTag from '../../../img/ico/deleteTag.svg'

import { addClassReq, addTagReq, getClassById } from '../../../service/requests';
import { closePopup } from '../../../actions/popup';
import { addClass } from '../../../actions/classes';

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   addClass: (payload) => {
     return dispatch(addClass(payload));
   }
 })

const AddClass = (props) => {

   const {
      closePopup, 
      addClass,
      info
      
   } = props;

   const [clas, setClas] = useState({ name: "" , tags: [], lang: "uk", learnLang : ""})
   const [nameError, setNameError] = useState(false);


   const handleKeyDown = (e) => {
      if (e.key === 'Enter' && e.target.value != "") {
         let updatedTags = [...clas.tags, e.target.value];
         setClas({...clas, tags: updatedTags })
         e.target.value = ""
      }
   }
   
   const onTagDelete = (tag) => {
      let updatedTags = clas.tags.filter(x => x != tag);
      setClas({...clas, tags: updatedTags })
   }

   const createClass = async () => {
      if (!clas.name) {
         setNameError(true)
         return;
      }

      try {
         const respClass = await addClassReq(clas);
         // addClass({ clas: respClass.data.class });
         info.onAddClass(respClass.data.class)
         closePopup();
       }
 
       catch (error) {
         console.log(error)
       }
     }

   return (
      <section className="my-6 mx-20">
         <PopupTitle title="Add Class"/>

         <div className="flex flex-col mt-3">
            <label className="text-gray font-light text-sm">Name</label>
            <input type="text" id="name" className={nameError ? "popupInput--error" : "popupInput"} onChange={e => setClas({...clas, name: e.target.value})} />
         </div>

         {/* <div className="flex flex-col mt-3">
            <label className="text-gray font-light text-sm">Tags</label>
            <input className="popupInput" onKeyDown={(e) => handleKeyDown(e)}></input>
         </div>

         <div className="flex mt-2 mb-4 flex-wrap">
            {clas.tags.map(tag => {
               return (
                  <div className="flex items-center rounded-md bg-brightLightPurple w-max px-2 py-0.5 my-1 mr-2 cursor-pointer transition border border-brightLightPurple hover:border-1 hover:border-purple">
                     <div className="text-purple text-xs">{tag}</div>
                     <img src={deleteTag} className="ml-2"  onClick={() => onTagDelete(tag)}/>
                  </div>
               )
            })}
         </div> */}

         <div className="flex flex-col mt-3">
            <label className="text-gray font-light text-sm">Class language (translate from)</label>
            <div className='mt-1.5'>
               <LanguageSelector lang={clas.learnLang} setLang={(learnLang) =>  setClas({...clas, learnLang: learnLang })}/>
            </div>
         </div>

         <div className="flex flex-col mt-3">
            <label className="text-gray font-light text-sm">Students' native language (translate to)</label>
            <div className='mt-1.5'>
               <LanguageSelector lang={clas.lang} setLang={(lang) =>  setClas({...clas, lang: lang })}/>
            </div>
         </div>

         

         <div className="mt-10 mx-auto w-min rounded-lg overflow-hidden">
            <ButtonBase><div onClick={createClass} className="mx-auto bg-darkPurple text-sm text-white py-2 px-7 w-max rounded-md cursor-pointer hover:bg-hoverPurple transition">Add Class</div></ButtonBase>
         </div>
      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
 )(AddClass);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './addWordTeacher.scss'

import { PurpleButtonSmall } from '../../buttons/buttons';
import {PopupTitle} from '../../title/title';
import ChipInput from 'material-ui-chip-input';
import Tooltip from "@material-ui/core/Tooltip";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { saveWord, updateWord } from '../../../service/requests';
import { openNotification } from '../../../actions/notification';
import { closePopup } from '../../../actions/popup';

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
})

const AddWordTeacher = (props) => {

   const {
      info,
      closePopup,
      openNotification,
   } = props;

   const collectionId = info.collectionId;
   const [word, setWord] = useState(info.wordData);
   const [speech, setSpeech] = useState(word.partsOfSpeech ? word.partsOfSpeech : []);
   const [synonyms, setSynonyms] = useState(word.synonyms ? word.synonyms : []);
   
   const [examples, setExamples] = useState(word.examples ? word.examples : []);
   const [copyExamples, setCopyExamples] = useState(examples ? [...examples] : []);

   const [definitions, setDefinitions] = useState(word.definitions ? word.definitions : []);
   const [copyDefinitions, setCopyDefinitions] = useState(definitions ? [...definitions] : []);

   const [isLoading, setIsLoading] = useState(false);



   const addNewInput = (inputName) => {
      if (inputName === 'examples') {
         if (examples[examples.length - 1].length) {
            setExamples([...examples, ""])
         }
      } else {
         if (definitions[definitions.length - 1].length) {
            setDefinitions([...definitions, ""])
         }
      }
   }

   const onDeleteItem = (index, inputName) => {
      if (inputName === 'examples') {
         let updatedExamples = examples.filter(x => x !== examples[index]);
         setExamples(updatedExamples)
      } else {
         let updatedDefinitions = definitions.filter(x => x !== definitions[index]);
         setDefinitions(updatedDefinitions)
      }

   }

   const updateArray = (e, index, type) => {
      if (type === 'examples') {
         examples[index] = e.target.value;
         setExamples(examples)
      } else {
         definitions[index] = e.target.value;
         setDefinitions(definitions)
      }
   }

   const onSaveWord = async () => {
      setIsLoading(true);
      let updatedExamples = examples && examples.filter(x => x !== "");
      let updatedDefinitions = definitions && definitions.filter(x => x !== "");
      try {
         const res = await saveWord({ wordData: { ...word, definitions: updatedDefinitions, examples: updatedExamples, synonyms: synonyms, partsOfSpeech: speech }, collectionId });
         info.onAddedWordEvent(res.data.word);
         openNotification({text: 'Word added successfully', notificationType: 'success' });
         closePopup();
      }
      catch (error) {
         console.log(error)
         openNotification({text: 'Something went wrong', notificationType: 'error' });
      }
      closePopup();
      setIsLoading(false);
   }

   const onUpdateWord = async () => {
      setIsLoading(true);
      let updatedExamples = examples && examples.filter(x => x !== "");
      let updatedDefinitions = definitions && definitions.filter(x => x !== "");
      const updatedWord = { ...word, definitions: updatedDefinitions, examples: updatedExamples, synonyms: synonyms, partsOfSpeech: speech };
      try {
         await updateWord(word._id, updatedWord);
         info.onWordUpdate(updatedWord)
         openNotification({text: 'Word updated successfully', notificationType: 'success' });
         closePopup();
      }
      catch (error) {
         console.log(error)
         openNotification({text: 'Something went wrong', notificationType: 'error' });
      }
      setIsLoading(false);
   }

   return (
      <section className="my-10 relative addWordTeacher">
         <div className='mx-20'><PopupTitle title={info.isEditMode ? 'Fix Translation' : 'Add Word'}/></div>


            <div className="flex mt-10 gap-5 box-border">
               <div className="flex flex-col flex-1">
                  <label for="word" className="text-gray dark:text-darkModeGray font-light text-sm cursor-pointer">Word</label>
                  <input id="word" type="text" defaultValue={word.word} onChange={(e) => setWord({ ...word, word: e.target.value })} className="addWordTeacher__input px-2 mt-1"></input>
               </div>
               <div className="flex flex-col flex-1">
                  <label for="translate" className="text-gray dark:text-darkModeGray font-light text-sm cursor-pointer">Translate</label>
                  <input id="translate" type="text" defaultValue={word.translatedText} onChange={(e) => setWord({ ...word, translatedText: e.target.value })} className="addWordTeacher__input px-2 mt-1"></input>
               </div>
            </div>
            
            <hr className='text-whitish dark:text-darkModeSeparationLine mt-3'/>

         <div className='addWordTeacher__content'>
            <div className="flex flex-col mt-2">
               <label for="speech" className="text-gray dark:text-darkModeGray font-light text-sm cursor-pointer">Speech</label>
               <ChipInput
                  value={speech}
                  onAdd={(y) => setSpeech([...speech, y])}
                  onDelete={(y, index) => setSpeech([...speech.filter(x => x !== y)])}
                  onClick={(y) => setSpeech([...speech.filter(x => x !== y.target.innerText)])}
                  disableUnderline='true'
                  className="chipInput"
               />
            </div>

            <div className="flex flex-col mt-4">
               <label for="speech" className="text-gray dark:text-darkModeGray font-light text-sm cursor-pointer">Synonyms</label>
               <ChipInput
                  value={synonyms}
                  onAdd={(y) => setSynonyms([...synonyms, y])}
                  onDelete={(y, index) => setSynonyms([...synonyms.filter(x => x !== y)])}
                  disableUnderline='true'
                  className="chipInput"
                  onClick={(y) => setSynonyms([...synonyms.filter(x => x !== y.target.innerText)])}
               />
            </div>

            <div className="flex flex-col mt-4">
               <label for="examples" className="text-gray dark:text-darkModeGray font-light text-sm cursor-pointer">Examples</label>
               {examples.length > 0 ? examples.map((x, index, array) => {
                  return (
                     index < examples.length - 1 ?
                        <div className="mt-1 relative" key={x}>
                           <textarea
                              rows="1"
                              type="text"
                              defaultValue={x}
                              onChange={(e) => updateArray(e, index, 'examples')}
                              className="addWordTeacher__input"
                              placeholder="Add your own example"></textarea>
                           <Tooltip title="Delete" placement="top">
                              <div className="addWordTeacher__removeBtn" onClick={() => onDeleteItem(index, 'examples')}><DeleteOutlineOutlinedIcon className="addWordTeacher__removeBtnIcon" /></div>
                           </Tooltip>
                        </div>

                        :
                        <div className="mt-1 relative" key={x}>
                           <textarea
                              rows="1"
                              type="text"
                              defaultValue={x}
                              onChange={(e) => updateArray(e, index, 'examples')}
                              className="addWordTeacher__input"
                              placeholder="Add your own example"></textarea>
                           <Tooltip title="Delete" placement="top">
                              <div className="addWordTeacher__removeBtn" onClick={() => onDeleteItem(index, 'examples')}><DeleteOutlineOutlinedIcon className="addWordTeacher__removeBtnIcon" /></div>
                           </Tooltip>
                           <Tooltip title="Add example" placement="top">
                              <div className="absolute top-0 right-0 m-1.5 bg-orange hover:bg-darkOrange transition rounded cursor-pointer" onClick={() => addNewInput('examples')}><AddRoundedIcon className="text-white" /></div>
                           </Tooltip>
                        </div>
                  )
               })
               : 
               <div className="mt-1 relative">
                           <textarea
                              type="text"
                              rows="3"
                              defaultValue={""}
                              onChange={(e) => updateArray(e, 0, 'examples')}
                              className="addWordTeacher__input"
                              placeholder="Add your own example"></textarea>
                           {/* <Tooltip title="Delete" placement="top">
                              <div className="addWordTeacher__removeBtn" onClick={() => onDeleteItem(0, 'examples')}><DeleteOutlineOutlinedIcon className="addWordTeacher__removeBtnIcon" /></div>
                           </Tooltip> */}
                           <Tooltip title="Add example" placement="top">
                              <div className="absolute top-0 right-0 m-1.5 bg-orange hover:bg-darkOrange transition rounded cursor-pointer" onClick={() => addNewInput('examples')}><AddRoundedIcon className="text-white" /></div>
                           </Tooltip>
                        </div>
               }
            </div>

            <div className="flex flex-col mt-4">
               <label for="definition" className="text-gray dark:text-darkModeGray font-light text-sm cursor-pointer">Definition</label>
               {definitions.length > 0 ? definitions.map((x, index, array) => {
                  return (
                     index < definitions.length - 1 ?

                        <div className="mt-1 relative" key={x}>
                           <textarea
                              rows="3"
                              type="text"
                              defaultValue={x}
                              onChange={(e) => updateArray(e, index, 'definitions')}
                              className="addWordTeacher__input"
                              placeholder="Add your own definition"></textarea>
                           <Tooltip title="Delete" placement="top">
                              <div className="addWordTeacher__removeBtn" onClick={() => onDeleteItem(index, 'definitions')}><DeleteOutlineOutlinedIcon className="addWordTeacher__removeBtnIcon" /></div>
                           </Tooltip>
                        </div>

                        :
                        <div className="mt-1 relative" key={x}>
                           <textarea
                              key={index}
                              type="text"
                              rows="3"
                              defaultValue={x}
                              onChange={(e) => updateArray(e, index, 'definitions')}
                              className="addWordTeacher__input"
                              placeholder="Add your own definition"></textarea>
                           <Tooltip title="Delete" placement="top">
                              <div className="addWordTeacher__removeBtn" onClick={() => onDeleteItem(index, 'definitions')}><DeleteOutlineOutlinedIcon className="addWordTeacher__removeBtnIcon" /></div>
                           </Tooltip>
                           <Tooltip title="Add definition" placement="top">
                              <div className="absolute top-0 right-0 m-1.5 bg-orange hover:bg-darkOrange transition rounded cursor-pointer" onClick={() => addNewInput('definitions')}><AddRoundedIcon className="text-white" /></div>
                           </Tooltip>
                        </div>
                  )
               }) : 
               <div className="mt-1 relative">
                           <textarea
                              type="text"
                              rows="3"
                              defaultValue={""}
                              onChange={(e) => updateArray(e, 0, 'definitions')}
                              className="addWordTeacher__input"
                              placeholder="Add your own definition "></textarea>
                           {/* <Tooltip title="Delete" placement="top">
                              <div className="addWordTeacher__removeBtn" onClick={() => onDeleteItem(0, 'definitions')}><DeleteOutlineOutlinedIcon className="addWordTeacher__removeBtnIcon" /></div>
                           </Tooltip> */}
                           <Tooltip title="Add definition" placement="top">
                              <div className="absolute top-0 right-0 m-1.5 bg-orange hover:bg-darkOrange transition rounded cursor-pointer" onClick={() => addNewInput('definitions')}><AddRoundedIcon className="text-white" /></div>
                           </Tooltip>
                        </div>
               }
            </div>

         </div>
         <div className='mt-7 relative'>
            {/* <div className='halfTransparentArea h-28 absolute w-full -top-28'></div> */}
            <div className="mx-auto w-min rounded-lg">
               <PurpleButtonSmall isLoading={isLoading} text={info.isEditMode ? "Update" : "Add"} onClick={info.isEditMode ? onUpdateWord : onSaveWord} />
            </div>
         </div>

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(AddWordTeacher);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useDarkSide from '../../hook/useDarkSide';
import { DarkModeSwitch } from 'react-toggle-dark-mode'
import { setThemeMode } from '../../actions/themeMode';

const mapDispatchToProps = dispatch => ({
  setThemeMode: (payload) => {
     return dispatch(setThemeMode(payload));
  }
})

const Switcher = (props) => {
  const {
    setThemeMode
  } = props;

  const [colorTheme, setColorTheme]= useDarkSide();
  const [darkSide, setDarkSide] = useState(colorTheme === "light" ? true : false);
  const [theme, setTheme] = useState(localStorage.theme);

  const toggleDarkMode = (checked) => {
    setColorTheme(colorTheme);
    setDarkSide(checked);
    setThemeMode({mode: colorTheme});
  };

  useEffect(() => {
    if (!theme || theme == "undefined") {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      if (darkThemeMq.matches) {
        setDarkSide(true);
        setColorTheme("dark");
      } else {
        setDarkSide(false);
        setColorTheme("light");
      }
    }
  }, []);
  
  return (
    <div className='flex items-center p-2 my-10 mx-8 justify-center'>
        <DarkModeSwitch
            checked={darkSide}
            onChange={toggleDarkMode}
            size={50}
            // sunColor="#bac0e6"
            sunColor="#ffce7a"
        />
        {/* <h3 className='text-gray-800 select-none dark:text-white w-min text-xs whitespace-nowrap'>{colorTheme === 'light' ? "Dark mode" : "Light mode"}</h3> */}
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(Switcher);

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ChooseRole from "../chooseRole/chooseRole";
import PhoneInput from "react-phone-number-input";
import {isValidPhoneNumber, isPossiblePhoneNumber} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {PurpleButtonWithLoading} from "../../components/buttons/buttons";
import {NavLink, Link, useLocation, useHistory} from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import GoogleAppleAuth from "../../components/googleAppleAuth/googleAppleAuth";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import "./signUp.scss";
import validator from 'validator';

// img
import logo from "../../img/logorwAnimated.svg";

import {checkUserExist} from "../../service/requests";
import {openNotification} from "../../actions/notification";

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const SignUp = (props) => {
  const {openNotification} = props;

  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState({
    name: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    name: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    agreementError: "",
  });

  const [checkAgreement, setCheckAgreement] = useState(false);
  const [existUserMessage, setExistUserMessage] = useState("");
  const [showChooseRole, setShowChooseRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });

    setError({
      ...error,
      [e.target.id]: "",
    });
  };

  // validation
  const validate = () => {
    let nameError = "";
    let lastNameError = "";
    let phoneError = "";
    let emailError = "";
    let passwordError = "";
    let agreementError = "";

    if (!user.name || !user.name.match(/[a-zA-Z]+/g)) {
      nameError = "Name can not be blank";
    }

    if (!user.lastName || !user.lastName.match(/[a-zA-Z]+/g)) {
      lastNameError = "Last name can not be blank";
    }

    if (!user.phone || !isPossiblePhoneNumber(user.phone)) {
      phoneError = "Please enter correct phone number";
    }

    if (!validator.isEmail(user.email)) {
      emailError = "Invalid email";
    }

    if (user.password.length < 5) {
      passwordError = "Password is too short";
    }

    if (!checkAgreement) {
      agreementError = "Please read and agree with Items & Conditions";
    }

    if (emailError || nameError || lastNameError || passwordError || agreementError || phoneError) {
      setError({
        name: nameError,
        lastName: lastNameError,
        phone: phoneError,
        email: emailError,
        password: passwordError,
        agreement: agreementError,
      });
      return false;
    }
    return true;
  };

  const signUp = async (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      setIsLoading(true);
      setError({
        name: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        agreementError: "",
      });

      try {
        const res = await checkUserExist({email: user.email});

        if (res.data.exist) {
          setExistUserMessage("This user already exist");
          return;
        }

        let updatedUser = {...user, name: user.name.trim(), lastName: user.lastName.trim()};
        history.push({
          pathname: "/chooseRole",
          state: {user: updatedUser, isGoogleAuth: false},
        });

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        openNotification({text: "Something went wrong, please check your info once again", notificationType: "error"});
      }
    }
  };

  const handlePhoneChange = (value) => {
    setUser({
      ...user,
      phone: value.phone,
    });

    setError({...error, phone: ""});
  };

  // const openPrivacy = () => {
  //       window.open("/privacy", "_blank");
  // }

  const signUpContent = () => {
    return (
      <>
        <div className="flex flex-col-reverse mt-10 relative">
          <input
            type="text"
            id="name"
            className={error.name ? "auth__input--error" : "auth__input"}
            onChange={(e) => {handleChange(e)}}
          />
          <label for="name" className="">
            Name
          </label>
          <div className="auth__textError">{error.name}</div>
          {existUserMessage && <div className="absolute top-0 right-0 text-xs text-redError">{existUserMessage}</div>}
        </div>
        <div className="flex flex-col-reverse mt-5 relative">
          <input
            type="lastName"
            id="lastName"
            className={error.lastName ? "auth__input--error" : "auth__input"}
            onChange={(e) => handleChange(e)}
          />
          <label for="lastName" className="">
            Last Name
          </label>
          <div className="auth__textError">{error.lastName}</div>
        </div>
        <div className="flex flex-col-reverse mt-5 relative hello">
          <PhoneInput
            defaultCountry="US"
            className={error.phone ? "phoneInput--error" : "phoneInput"}
            placeholder="+"
            type="phone"
            international={true}
            onChange={(phone) => handlePhoneChange({phone})}
            disableDropdown
            id="phone"
          />
          <label for="phone" className="">
            Phone Number
          </label>
          <div className="auth__textError">{error.phone}</div>
        </div>
        <div className="flex flex-col-reverse mt-5 relative">
          <input
            type="email"
            id="email"
            className={error.email ? "auth__input--error" : "auth__input"}
            onChange={(e) => handleChange(e)}
          />
          <label for="email" className="">
            Email
          </label>
          <div className="auth__textError">{error.email}</div>
        </div>
        <div className="flex flex-col-reverse mt-5 relative">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className={error.password ? "auth__input--error pr-14" : "auth__input pr-14"}
            onChange={(e) => handleChange(e)}
          />
          <label for="password" className="">
            Password
          </label>
          <div className="auth__textError">{error.password}</div>
          <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              className="w-min border absolute right-1"
            >
              {showPassword ? <VisibilityOffIcon className="text-numberGray dark:text-white" /> : <VisibilityIcon className="text-numberGray dark:text-white" />}
            </IconButton>
        </div>

        <div className="auth__formAgreement cursor-pointer relative">
          <input
            className="auth__checkbox"
            type="checkbox"
            id="agreement"
            name="agreement"
            onChange={() => setCheckAgreement(!checkAgreement)}
          ></input>
          <div className="auth__checkboxCustom"></div>
          <label for="agreement" className="auth__formAgreementLabel">
            By Signing Up i agree with{" "}
            <Link target="_blank" to="/privacy">
              <span>Items & Conditions</span>
            </Link>
          </label>
          {/* <label for="agreement" className="auth__formAgreementLabel">By Signing Up i agree with <span onClick={() => openPrivacy()}>Items & Conditions</span></label> */}
          <div className="auth__textError auth__agreementErrorText text-left">{error.agreement}</div>
        </div>

        <div className="mx-auto w-max mt-14">
          <PurpleButtonWithLoading isLoading={isLoading} onClick={signUp} text="Sign Up" />
        </div>

        <GoogleAppleAuth setIsGoogleAuthLoading={(isLoading) => setIsGoogleAuthLoading(isLoading)} />

        <div className="text-sm text-darkBlue dark:text-whitish text-center mt-8">
          Already have an account?
          <NavLink to="/logIn">
            <span className="font-semibold text-purple ml-2 cursor-pointer transition hover:text-hoverPurple">
              Log In
            </span>
          </NavLink>
        </div>
      </>
    );
  };

  return (
    <div className="authWrapper">
      <object type="image/svg+xml" className="w-32 mx-auto" data={logo}></object>
      <div className="fadeIn relative">
        {isGoogleAuthLoading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress size={100} />
          </div>
        )}
        <div className={isGoogleAuthLoading ? "opacity-10 pointer-events-none" : ""}>{signUpContent()}</div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(SignUp);

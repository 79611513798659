import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { getRecentlyCollectionsReq } from '../../service/requests';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom';
import Switcher from '../switcher/switcher';

// styles
import './menu.scss';


// images

import { setRecentlyUsedCollections } from '../../actions/collections';

const mapStateToProps = state => ({
   ...state.currentUser,
   ...state.collections,
 });

 const mapDispatchToProps = dispatch => ({
   setRecentlyUsedCollections: (payload) => {
      return dispatch(setRecentlyUsedCollections(payload));
   }
})
 
const Menu = (props) => {
   const {
      user,
      recentlyUsedCollections
   } = props;

   const [recentCollections, setRecentCollections] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [isTeacher, setIsTeacher] = useState(false);
   const location = useLocation();

   useEffect( async () => {
      try {
         const res = await getRecentlyCollectionsReq();
         if(res) {
            setRecentCollections(res.data.collections);
            setRecentlyUsedCollections({collections: res.data.collections})
            setIsLoading(false)
         }
         
      } catch(e) {
         console.log('error', e)
         setIsLoading(false)
      }
    }, [])

   
  useEffect(() => {
      setRecentCollections(recentlyUsedCollections);
  }, [recentlyUsedCollections])

    useEffect(() => {
      if(user.role === 'teacher') { setIsTeacher(true) };    
    }, [])

   return (
      <div className="menu__wrapper bg-white dark:bg-darkModeDark">
         <div className="menu">
         <div>
            <NavLink to="/">
               <img className="px-10" src="/images/logo.svg"/>
            </NavLink>

            <div className="flex-column text-dark dark:text-white text-sm capitalize ml-10 mt-16">
               <div className="text-base uppercase text-gray dark:text-darkModeWhiteTransparent font-light">pages</div>


               <NavLink to="/" exact activeClassName="menuItem--active" >
                  <div className="flex my-5 cursor-pointer">
                     <img src="/images/ico/home.svg" />
                     <div className="ml-4">home</div>
                  </div>
               </NavLink>

               <NavLink to="/collections" activeClassName="menuItem--active" className={location.pathname.includes('collection') && 'menuItem--active'}>
               {/* <NavLink to="/collections" exact={true} onlyActiveOnIndex activeClassName="menuItem--active"> */}
                  <div className="flex my-5">
                     <img src="/images/ico/collections.svg" />
                     <div className="ml-4 ">collections</div>
                  </div>
               </NavLink>

               <NavLink to="/practice" activeClassName="menuItem--active" >
                  <div className="flex my-5">
                     <img src="/images/ico/practice.svg" />
                     <div className="ml-4">practice</div>
                  </div>
               </NavLink>

               <NavLink to="/classes" activeClassName="menuItem--active" className={location.pathname.includes('class') && 'menuItem--active'}>
                  <div className="flex my-5">
                     <img src="/images/ico/student.svg" />
                     <div className="ml-4">Classes</div>
                  </div>
               </NavLink>


               {/* <NavLink to="/support" activeClassName="menuItem--active" > */}
               <div className='text-textLightGray dark:text-textDarkGray' style={{pointerEvents: 'none' }}>
                  <div className="flex my-5 relative">
                     <img src="/images/ico/support.svg" style={{filter: 'grayscale(100%) opacity(40%)'}}/>
                     <div className="ml-4">support</div>

                     <div className="commingSoonLabel">comming soon</div>
                  </div>

               </div>
               {/* </NavLink> */}

            </div>

            <div className="flex-column text-dark dark:text-white text-sm capitalize ml-10 mb-10 mt-32 w-36">
            {/* { isTeacher 
            ? <div className="text-base uppercase text-gray font-light">last classes</div>
            : <div className="text-base uppercase text-gray font-light">recently used</div>
            } */}

            <div className="text-base uppercase text-gray dark:text-darkModeWhiteTransparent font-light">recently used</div>
            {isLoading && <CircularProgress size={15} className="mt-5"/> }
            {recentCollections && recentCollections.length > 0 && recentCollections.slice(0, 5).map(collection => {
               return (
                  <NavLink to={`/collection/${collection._id}`} activeClassName="menuItem--active" >
                  <div className="flex my-5 items-start">
                     <img src="/images/ico/folderIcon.svg" />
                     <div className="sidebar__menuItem">{collection.name}</div>
                  </div>
                  </NavLink>
               )
            })}

            </div>
            {/* <div className="flex-column text-dark text-sm capitalize ml-10 mt-32">
               { isTeacher && 
                  <div className="text-base uppercase text-gray font-light">organization</div>
               }
            </div> */}
         </div>
   
         <div className="flex flex-col mt-auto">
            <Switcher/>
         <div className="flex mx-10 justify-between">
            <a href=""><img src="/images/ico/instagram.svg" /></a>
            <a href=""><img src="/images/ico/facebook.svg" /></a>
            <a href=""><img src="/images/ico/twitter.svg" /></a>
         </div>
         </div>

</div>
      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
 )(Menu);
 
import React            from 'react';
import { connect }      from 'react-redux';
import Dialog           from '@material-ui/core/Dialog';
import DialogContent    from '@material-ui/core/DialogContent';

import EditCollection   from '../popups/editCollection/editCollection';
import ShareCollection  from '../popups/shareCollection/shareCollection';
import LoadCollection   from '../popups/loadCollection/loadCollection';
import AddStudent       from '../popups/addStudent/addStudent';
import Student          from '../popups/student/student';
import AddWord          from '../popups/addWord/addWord';
import CreateCollection from '../popups/createCollection/createCollection';
import AddTag           from '../popups/addTag';
import Request          from '../popups/request/request'; 
import CreateRequest    from '../popups/createRequest/createRequest';
import AddToCollection  from '../popups/addToCollection/addToCollection';
import UploadImage      from '../popups/uploadImage/uploadImage';
import DeleteWord       from '../popups/deleteWord/deleteWord';
import AddClass         from '../popups/addClass/addClass';
import EditClass        from '../popups/editClass/editClass';
import LeaveClass       from '../popups/leaveClass/leaveClass';
import DeleteClass      from '../popups/deleteClass/deleteClass';
import AddWordTeacher   from '../popups/addWordTeacher/addWordTeacher';
import ChangePassword   from '../popups/changePassword/changePassword';
import CreatePassword   from '../popups/createPassword/createPassword';
import Confirmation     from '../popups/confirmation/confirmation';

// icons
import closeIcon from '../../img/ico/closeIcon.svg'
import closeIconWhite from '../../img/ico/closeIconWhite.svg'


import {
   closePopup,
} from '../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
})

const Popup = (props) => {

   const theme = localStorage.getItem("theme");

   
   const handleClose = () => {
      props.closePopup();
   }
   
   const map = {
      "EditCollection": EditCollection,
      "ShareCollection": ShareCollection,
      "LoadCollection": LoadCollection,
      "AddStudent": AddStudent,
      "Student": Student,
      "AddWord": AddWord,
      "CreateCollection": CreateCollection,
      'AddTag': AddTag,
      'Request': Request,
      'CreateRequest': CreateRequest,
      'AddToCollection': AddToCollection,
      'UploadImage': UploadImage,
      'DeleteWord': DeleteWord,
      'AddClass': AddClass,
      'EditClass': EditClass,
      'LeaveClass': LeaveClass,
      'DeleteClass': DeleteClass,
      'AddWordTeacher': AddWordTeacher,
      'ChangePassword': ChangePassword,
      'CreatePassword': CreatePassword,
      'Confirmation': Confirmation
   }


   const CurrentPopup = map[props.currentPopup];
   if (props.currentPopup === 'AddWord') {
      return (
         <div>

            <Dialog
               open={props.showPopup}
               onClose={handleClose}
               maxWidth="lg"
               className="dialog"
               transitionDuration={100}
            >
               <DialogContent className="dialog addWord">
                  <CurrentPopup info={props.info} />
               </DialogContent>

            </Dialog>
         </div>
      )
   } else {
      return (
         <Dialog
            open={props.showPopup}
            onClose={handleClose}
            maxWidth="lg"
            className="dialog"
            transitionDuration={100}
         >
            <DialogContent className="dialog--blurred dialog customBorder bg-whiteTransparent dark:bg-darkModeDark">
               <img src={theme ? theme === 'dark' ? closeIconWhite : closeIcon : closeIcon} alt="" onClick={handleClose} className='absolute top-6 right-6 cursor-pointer' />
               <CurrentPopup info={props.info} />
            </DialogContent>

         </Dialog>
      )
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Popup);
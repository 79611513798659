import React from 'react'

export const PageTitle = (props) => {
  const {
    title
  } = props;
  return (
    <div className="text-dark dark:text-whitish font-medium text-2xl mt-12">{title}</div>
  )
}

export const PopupTitle = (props) => {
  const {
    title
  } = props;
  return (
    <div className="text-dark dark:text-white text-lg font-medium text-center">{title}</div>
  )
}

import React, { useEffect } from "react";
import whiteFolder from "../../../img/whiteFolder.svg";
import CircularProgressbarComponent from "../../../components/circularProgressbar/circularProgressbar";
import ReactCountryFlag from "react-country-flag";
import { baseURL } from "../../../service/requests";
import defaultUserIcon from "../../../img/ico/defaultUser.svg";
import { getNativeLang } from '../../../utils/utils';


const Student = (props) => {
  const student = props.info.student;
  const flagCode = getNativeLang(student.nativeLang)?.flag;

  const getUserPhoto = (imagePath) => {
      if (imagePath) {
        if (imagePath.includes("http") || imagePath.includes("https")) {
          return imagePath;
        } else {
          return `${baseURL}images/users/${imagePath}`;
        }
      } else {
        return defaultUserIcon;
      }
    };

  return (
    <section className="my-6 mx-20 text-sm text-dark dark:text-white">
      <div className="mx-auto rounded-full w-20 h-20 border-2 border-white overflow-hidden">
        <img src={getUserPhoto(student.avatar)} alt="students avatar" className="object-cover w-full h-full"  />
      </div>
      <div className="mt-3 text-lg font-medium text-center">{`${student.name} ${student.lastName}`}</div>
      <div className="mt-1 flex mx-auto w-max items-center">
        <div className="text-xs uppercase mr-2">{student.nativeLang}</div>
        <ReactCountryFlag countryCode={flagCode} svg />
      </div>

      <div className="mt-5">
        <div className="flex mt-1">
          <div className="text-numberGray w-16">Email:</div>
          <div className="ml-4">{student.email}</div>
        </div>
        {student.phone &&
          <div className="flex mt-1">
            <div className="text-numberGray w-16">Number:</div>
            <div className="ml-4">{student.phone}</div>
          </div>
        }
      </div>
      {/* <div className="mt-5">
        <div className="py-2 px-5 border border-lightGray bg-white rounded-lg flex items-center my-3">
          <img src={whiteFolder} />
          <div className="flex-col ml-2">
            <div className="font-medium">Collection 1</div>
            <div className="text-gray font-light text-xs">26 words</div>
          </div>
          <div className="ml-auto">
            <CircularProgressbarComponent percentage="15" />
          </div>
        </div>
        <div className="py-2 px-5 border border-lightGray bg-white rounded-lg flex items-center my-3">
          <img src={whiteFolder} />
          <div className="flex-col ml-2">
            <div className="font-medium">Collection 1</div>
            <div className="text-gray font-light text-xs">26 words</div>
          </div>
          <div className="ml-auto">
            <CircularProgressbarComponent percentage="20" />
          </div>
        </div>
        <div className="py-2 px-5 border border-lightGray bg-white  rounded-lg flex items-center my-3">
          <img src={whiteFolder} />
          <div className="flex-col ml-2">
            <div className="font-medium">Collection 1</div>
            <div className="text-gray font-light text-xs">26 words</div>
          </div>
          <div className="ml-auto">
            <CircularProgressbarComponent percentage="70" />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Student;

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { PurpleButton, EmptyButton, DisableButton } from '../../components/buttons/buttons';

// animated logo
import logo from '../../img/logorwAnimated.svg';

// icons
import phoneIcon from '../../img/ico/phoneIconRecovery.svg';
import emailIcon from '../../img/ico/emailIconRecovery.svg';

// illustrations
import emailIllustration from '../../img/sendEmailIllustration.svg';
import invitationIllustration from '../../img/invitationIllustration.svg';


const RecoveryPassword = () => {

   const history = useHistory();
   const [currentSection, setCurrentSection] = useState('passwordRecoveryMethod');
   const [recoveryMethod, setRecoveryMethod] = useState();
   const [passwordError, setPasswordError] = useState();
   const [smsCode, setSmsCode] = useState();
   const [userPassword, setUserPassword] = useState({
      password: '',
      repeatedPassword: ''
   });

   const handleChange = (e) => {
      setUserPassword({
         ...userPassword,
         [e.target.id]: e.target.value
      });
   }

   const onChooseRecoveryMethod = () => {
      if (recoveryMethod === "email") {
         setCurrentSection('resetPasswordMessage');
      } else {
         setCurrentSection('passwordRecoveryCode');
      }
   }

   const onCodeChange = (smsCode) => setSmsCode(smsCode);

   const onConfirmNewPassword = () => {
      if(userPassword.password == userPassword.repeatedPassword) {
         history.push("/login");
      } else {
         setPasswordError("Passwords not equal");
      }
   }

   return (
          <div className="authWrapper">
         <object type="image/svg+xml" className="w-32 mx-auto" data={logo}></object>
         { currentSection === 'passwordRecoveryMethod' &&
            <div className="fadeIn text-center">
               <div className="mt-14 font-medium text-xl">Forgot Password?</div>
               <div className="mt-5 text-gray font-light">Choose a password recovery method</div>
               <div className="flex justify-evenly my-10">
                  {/* <div className="chooseRole__disabled"> */}
                  <div className={recoveryMethod === 'phone' ? "mr-10 chooseRole__role--active" : "mr-10 chooseRole__role"} onClick={() => setRecoveryMethod("phone")}>
                     <img src="/images/ico/check.svg" alt="checkmark" className="chooseRole__checkRoleIcon" />
                     <img src={phoneIcon} alt="Phone icon" />
                     <div className="chooseRole__roleNameLight">Phone number</div>
                  </div>

                  <div className={recoveryMethod === 'email' ? "chooseRole__role--active" : "chooseRole__role"} onClick={() => setRecoveryMethod("email")}>
                     <img src="/images/ico/check.svg" alt="checkmark"  className="chooseRole__checkRoleIcon" />
                     <img src={emailIcon} alt="Email icon" />
                     <div className="chooseRole__roleNameLight">E-mail</div>
                  </div>
               </div>
               <PurpleButton text="Next" onClick={onChooseRecoveryMethod} />
            </div>
         }

         { currentSection === 'passwordRecoveryCode' &&
            <div className="fadeIn text-center">
               <div className="mt-14 font-medium text-xl">Forgot Password?</div>
               <div className="mt-5 text-gray font-light text-lg">We have sent an SMS with a code to your number, enter it below to reset your password</div>

               <div className="mx-auto mt-10 w-min">
                  <OtpInput
                     value={smsCode}
                     onChange={onCodeChange}
                     numInputs={4}
                     inputStyle="passwordRecoveryCode"
                     focusStyle="passwordRecoveryCode--focus"
                     isInputNum="true"
                     shouldAutoFocus="true"
                  />
               </div>
               <div className="my-10 text-textDarkGray font-light text-lg">The code can be sent again via:<span className="font-medium text-dark ml-3">00:32 </span></div>
               <PurpleButton text="Reset" onClick={() => setCurrentSection("newPassword")}/>
            </div>
         }

         { currentSection === 'newPassword' &&
            <div className="fadeIn">
               <div className="text-center mt-14 font-medium text-xl">Reset Password</div>
               <div className="text-center mt-5 text-gray font-light">Enter a new password</div>
                  <div className="flex flex-col-reverse mt-10 relative">
                     <input type="password" id="password" className="auth__input" onChange={e => handleChange(e)} />
                     <label for="password" className="">New Password</label>
                     {passwordError && <div className="absolute top-0 right-0 text-xs text-redError">{passwordError}</div>}
                  </div>
                  <div className="flex flex-col-reverse mt-7 relative">
                     <input type="password" id="repeatedPassword" className="auth__input" onChange={e => handleChange(e)}/>
                     <label for="repeatedPassword" className="">Confirm Password</label>
                  </div>
               <div className="mt-10 text-center">
               {userPassword.password.length < 5 
                  ? <DisableButton text="Save"/>
                  : <PurpleButton text="Save" onClick={onConfirmNewPassword}/>
               }
               </div>
            </div>
         }

         { currentSection === 'resetPasswordMessage' &&
            <div className="fadeIn">
               <div className="text-center mt-14 font-medium text-xl">Reset Password</div>
               <div className="text-center mt-5 text-gray font-light">We sent you a letter by mail, follow the link and create a new password</div>
               <img src={emailIllustration} className="mx-auto mt-10" alt="We sent you a letter by mail, follow the link and create a new password" />
            </div>
         }


          {/* { currentSection === 'inviteFromOrganization' &&
            <div className="fadeIn">
               <div className="text-center mt-14 font-medium text-xl">Invite from <span className="text-purple">Green Forest School</span></div>
               <div className="text-center mt-5 text-gray font-light text-dark">If you are ready to become a teacher at <span className="font-medium">Green Forest School,</span> please confirm the request!</div>
               <img src={invitationIllustration} className="mx-auto mt-10" alt="We sent you a letter by mail, follow the link and create a new password" />
               <div className="mt-10 mx-auto w-min flex gap-5">
                  <EmptyButton text="Cancel" />
                  <PurpleButton text="Confirm" />
               </div>
            </div>
         } */}
         </div>
   )
}

export default RecoveryPassword

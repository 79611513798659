import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import CircularProgress from "@material-ui/core/CircularProgress";


export const PurpleButton = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className="bg-darkPurple dark:bg-darkModeDeepPurple dark:hover:bg-hoverPurple px-10 py-2 rounded-md text-white text-lg mx-auto w-min whitespace-nowrap hover:bg-hoverPurple transition cursor-pointer">{props.text}</div>
      </ButtonBase>
   )
}

export const PurpleButtonWithLoading = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className={props.isLoading ? "bg-lightGray dark:bg-darkModeDisabled px-10 py-2 rounded-md text-white text-lg mx-auto w-min whitespace-nowrap pointer-events-none" : "bg-darkPurple dark:bg-darkModeDeepPurple px-10 py-2 rounded-md text-white text-lg mx-auto w-min whitespace-nowrap hover:bg-hoverPurple transition cursor-pointer"}> {props.isLoading && <CircularProgress size={14} color="#ffffff" className="mr-4"/> }{props.text}</div>
      </ButtonBase>
   )
}


export const EmptyButton = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className=" hover:bg-purpleTransparent dark:hover:bg-darkModeTransparentPurple px-10 py-1.5 rounded-md text-purple border border-purple text-lg mx-auto w-min whitespace-nowrap hover:bg-purpleTransparent transition cursor-pointer">{props.text}</div>
      </ButtonBase>
   )
}

export const PurpleButtonSmall = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className={props.isLoading 
            ? "box-border border border-transparent bg-lightGray dark:bg-darkModeDisabled px-8 py-1.5 text-sm rounded-md text-white mx-auto w-min whitespace-nowrap pointer-events-none"  
            : "box-border border border-transparent bg-darkPurple dark:bg-darkModeDeepPurple dark:hover:bg-hoverPurple px-8 py-1.5 rounded-md text-sm text-white mx-auto w-min whitespace-nowrap hover:bg-hoverPurple transition cursor-pointer"}> {props.isLoading && <CircularProgress size={12} color="#ffffff" className="mr-2"/> }{props.text}</div>
      </ButtonBase>
   )
}

export const EmptyButtonSmall = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} 
         className="px-8 py-1.5 rounded-md text-purple text-sm border border-purple font-normal mx-auto w-min whitespace-nowrap hover:bg-purpleTransparent dark:hover:bg-darkModeTransparentPurple transition cursor-pointer box-border">{props.text}</div>
      </ButtonBase>
   )
}

export const DisableButton = (props) => {
   return (
      <ButtonBase>
         <div className="px-10 py-2 rounded-md bg-lightGray dark:bg-darkModeDisabled text-white text-lg mx-auto w-min whitespace-nowrap pointer-events-none">{props.text}</div>
      </ButtonBase>
   )
}

export const DisableButtonSmall = (props) => {
   return (
         <div className="bg-lightGray dark:bg-darkModeDisabled px-8 py-1.5 text-sm rounded-md text-white font-light mx-auto w-min border border-transparent whitespace-nowrap pointer-events-none">{props.text}</div>
   )
}

export const PurpleLightButtonSmall = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className="bg-practiceLightPurple dark:bg-darkModeTransparentPurple px-8 text-sm py-1.5 rounded-md text-purple font-light border border-transparent mx-auto w-min whitespace-nowrap hover:border-purple transition cursor-pointer">{props.text}</div>
      </ButtonBase>
   )
}

export const DeleteButton = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className="bg-transparentRed px-6 py-1 rounded-md text-redError border border-transparent mx-auto w-min whitespace-nowrap hover:border-red transition cursor-pointer">{props.text}</div>
      </ButtonBase>
   )
}

export const NextStepButton = (props) => {
      return (
         <ButtonBase>
            <div onClick={props.onClick} className="bg-darkPurple dark:bg-darkModeDeepPurple dark:hover:bg-hoverPurple px-8 py-1.5 rounded-md text-white font-normal mx-auto w-min whitespace-nowrap hover:bg-hoverPurple transition cursor-pointer">{props.text}<span className='ml-3'><ArrowForwardRoundedIcon fontSize='small'/></span></div>
         </ButtonBase>
      )
}

export const PevStepButton = (props) => {
   return (
      <ButtonBase>
         <div onClick={props.onClick} className="bg-practiceLightPurple px-8 text-sm py-2 rounded-md text-purple font-light border border-transparent mx-auto w-min whitespace-nowrap hover:border-purple transition cursor-pointer"><span className='mr-3'><ArrowBackRoundedIcon fontSize='small'/></span>{props.text}</div>
      </ButtonBase>
   )
}
import store from '../store';

export const getNativeLang = (lang) => {
   const state = store.getState();
   const languages = state.languages.languages;

    if(lang) {
        const currentLang = languages.find((x) => x.code === lang);
        if(currentLang) {
           return currentLang
        } else {
           return null
        }
     }
}
import React from 'react'
import { connect } from 'react-redux';
import { PurpleButtonSmall, EmptyButtonSmall } from '../../../components/buttons/buttons';
import {PopupTitle} from '../../title/title';
import { deleteClassReq } from '../../../service/requests';


import { closePopup } from '../../../actions/popup';

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   }
})

const LeaveClass = (props) => {

   const {
      closePopup,
      info
   } = props;



   const onClassLeave = async () => {
      // closePopup()
   }

   return (
      <section className="my-10 mx-20">
         <PopupTitle title="Leave class?"/>

         <div className="mt-8 text-center text-textGray font-light">
   Are you sure you want to leave a <b>{info.clas.name}</b> class?
         </div>

         <div className="mx-auto w-max mt-12 flex gap-5">
            <EmptyButtonSmall text="Cancel" onClick={() => closePopup()} />
            <PurpleButtonSmall text="Yes, leave" onClick={() => onClassLeave()} />
         </div>

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(LeaveClass);

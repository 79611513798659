import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import store from "./store";
import "./index.scss";
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {GoogleOAuthProvider} from "@react-oauth/google";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
      <GoogleOAuthProvider clientId="119326919458-efacc3jlri69hh9erltn4cae3q3kh8mc.apps.googleusercontent.com">
          <App />
          </GoogleOAuthProvider>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

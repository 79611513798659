import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  PurpleButtonSmall,
  EmptyButtonSmall,
  PurpleButton,
  EmptyButton,
} from "../../../components/buttons/buttons";
import { deleteWordFromCollection } from "../../../service/requests";

import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import trashIcon from "../../../img/ico/trashIcon.svg";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const Confirmation = (props) => {
  const {
    closePopup,
    info,
    //   openNotification
  } = props;

  const [isLoading, setIsLoading] = useState(props.info.isLoading);

  const onConfirm = () => {
    setIsLoading(true);
    closePopup();
    info.onConfirm();
  };

  return (
    <section className="my-10 mx-20 relative ">
      {isLoading ? (
        <div className="">
          <div className='animatedLoaderLogoWrapper'><img className='animatedLoaderLogo' src="/images/logo.svg" /></div>
        </div>
      ) : (
        <>
          <img src={trashIcon} className="m-auto" />
          <div className="mt-3 text-dark dark:text-white text-2xl font-medium text-center">
            {info.title}?
          </div>
          <div className="mt-3 text-center text-dark dark:text-white font-light">
            Are you sure you want to {info.text} ?
          </div>

          <div className="mx-auto w-max mt-10 flex gap-5">
            <EmptyButtonSmall text="Cancel" onClick={() => closePopup()} />
            <PurpleButtonSmall text={info.yesBtnText ? info.yesBtnText : "Yes, delete"} onClick={() => onConfirm()} />
          </div>
        </>
      )}
    </section>
  );
};

export default connect(null, mapDispatchToProps)(Confirmation);
